<template>
    <div class="compound-chart">
        <v-list-item class="px-0">
            <v-list-item-content class="py-0">
                <v-list-item-title class="headline d-flex align-content-center px-0">
                    <div class="flex-grow-1">
                        {{title}}
                    </div>
                    <v-btn text icon color="dark" v-show="!isEmpty && !visibleText" @click="onClickViewText()">
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                    <v-btn text icon color="dark" v-show="!isEmpty" @click="onClickToggleChart()">
                        <v-icon v-show="visibleFunnel">{{pieIcon}}</v-icon>
                        <v-icon v-show="visiblePie">{{funnelIcon}}</v-icon>
                        <v-icon v-show="visibleText">{{lastChartIcon}}</v-icon>
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>


        <div class="compound-chart__content">
            <div class="compound-chart__empty" v-show="visibleEmpty">
                <p class="subtitle-1">
                    {{ $t(`messages['no activity']`) }}
                </p>
            </div>

            <div class="compound-chart__progress" v-show="loading">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </div>

            <div class="compound-chart__charts" v-show="visibleTypes">
                <funnel-chart class="compound-chart__item"
                              :class="{'compound-chart__item_visible': visibleFunnel}"
                              v-model="getChartPoints"/>

                <pie-chart class="compound-chart__item"
                           :class="{'compound-chart__item_visible': visiblePie}"
                           v-model="getChartPoints"/>

                <items-list class="compound-chart__item compound-chart__item_list"
                            :class="{'compound-chart__item_visible': visibleText}"
                            v-model="getPoints"/>
            </div>
        </div>
    </div>
</template>

<script>
import FunnelChart from './charts/FunnelChart';
import PieChart from './charts/PieChart';
import ItemsList from './ItemsList';

const isEmptyData = (data) => data.every(({ value }) => value === 0);
const numberOrZero = (value) => isNaN(value) ? 0 : value;

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { FunnelChart, PieChart, ItemsList },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visibleType: 'funnel',
            lastChartType: 'funnel'
        };
    },
    computed: {
        visibleFunnel() {
            return this.visibleType === 'funnel';
        },

        visiblePie() {
            return this.visibleType === 'pie';
        },

        visibleText() {
            return this.visibleType === 'text';
        },

        pieIcon() {
            return 'mdi-chart-pie';
        },

        funnelIcon() {
            return 'mdi-filter';
        },

        lastChartIcon() {
            return this.lastChartType === 'funnel' ? this.funnelIcon : this.pieIcon;
        },

        isEmpty() {
            return isEmptyData(this.data);
        },

        visibleEmpty() {
            return !this.loading && this.isEmpty;
        },

        visibleTypes() {
            return !this.isEmpty && !this.loading;
        },

        getPoints() {
            const total = this.data.reduce((res, { value }) => res + value, 0);
            return this.data.map(item => ({
                name: item.label,
                count: item.value,
                chart: item.chart,
                percents: numberOrZero(item.value / total * 100),
                funnelCount: ''
            })).sort((a, b) => b.count - a.count);
        },

        getChartPoints() {
            return this.getPoints.filter(point => point.chart !== false);
        }
    },
    methods: {
        displayPie() {
            this.visibleType = 'pie';
        },

        displayFunnel() {
            this.visibleType = 'funnel';
        },

        getNextChart() {
            return this.visibleType === 'pie' ? 'funnel' : 'pie';
        },

        onClickToggleChart() {
            if (this.visibleText) {
                this.visibleType = this.lastChartType;
            } else if (this.visibleFunnel) {
                this.displayPie();
            } else {
                this.displayFunnel();
            }
        },

        onClickViewText() {
            this.lastChartType = String(this.getNextChart());
            this.visibleType = 'text';
        },
    }
}
</script>

<style>
    .compound-chart {
        width: 100%;
        padding: 16px 16px;
        box-sizing: border-box;
    }

    .compound-chart__content {
        position: relative;
        height: 350px;
        width: 100%;
    }

    .compound-chart__progress {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .compound-chart__charts {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .compound-chart__item {
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
    }

    .compound-chart__item_visible {
        opacity: 1;
        visibility: visible;
    }

    .compound-chart__item_list {
        overflow: auto;
    }

    .compound-chart__empty {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
</style>
