<template>
    <div>
        <portal to="toolbar">
            <v-tabs
                class="gray-tabs toolbar-tabs" slider-color="orange" background-color="blue-grey lighten-5"
                v-model="tab">

                <v-tab>
                    {{ $t(`tabs['summary stats']`) }}
                </v-tab>

                <v-tab>
                    {{ $t(`tabs['places stats']`) }}
                </v-tab>
            </v-tabs>
        </portal>

        <v-tabs-items v-model="tab">

            <v-tab-item :transition="false" :reverse-transition="false">
                <v-container fluid>
                    <div class="d-flex align-center">
                        <div class="headline flex"> {{ $t(`headers['ranged stats']`, [getFilterRange]) }}</div>
                        <div>
                            <place-filter
                                :multiple="false"
                                class="page-photographer__filter"
                                v-model="filter"
                                @submit="onSubmitFilter()"
                            />
                        </div>
                    </div>
                    <!--                    <v-row>-->
                    <!--                        <v-col class="d-flex align-center" cols="auto">-->
                    <!--                            <div class="headline">Статистика {{ getFilterRange }}</div>-->
                    <!--                        </v-col>-->

                    <!--                            <place-filter-->
                    <!--                                    :multiple="false"-->
                    <!--                                    class="page-photographer__filter"-->
                    <!--                                    v-model="filter"-->
                    <!--                                    @submit="onSubmitFilter()"-->
                    <!--                            />-->
                    <!--                       -->
                    <!--                    </v-row>-->

                    <v-row>
                        <v-col cols="12" lg="4" md="6" sm="10" v-for="(chart, i) in getCharts" :key="i">
                            <v-card class="mx-auto chart-card">
                                <compound-chart
                                    :data="chart.data"
                                    :title="chart.title"
                                    :loading="loading"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>

            <v-tab-item :transition="false" :reverse-transition="false">
                <place-table class="mt-3" :params="getPlacesParams" context="photographer"/>
            </v-tab-item>

        </v-tabs-items>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import dayjs from "dayjs"
import * as Format from "../util/format"
import CompoundChart from "../components/CompoundChart"
import PlaceFilter from "../components/place/Filter"
import PlaceTable from "../components/place/TableController"

const defaultFilter = () => {
    const now = dayjs()
    return {
        begin: now.startOf("day").toDate(),
        end: now.endOf("day").toDate(),
    }
}

export default {
    components: { CompoundChart, PlaceFilter, PlaceTable },
    head() {
        return {
            title: this.$t("titles.photographer", [this.getPhotographerName]),
            toolbar: this.getPhotographerName,
        }
    },
    data() {
        const { params } = this.$route
        return {
            tab: 0,
            loading: false,
            photographer: {},
            statistic: {},
            photographerId: params.id,
            filter: defaultFilter(),
            submittedFilter: defaultFilter(),
        }
    },

    fetch({ store, query, params, redirect }) {
        if (!store.getters["common/isAuthorized"]) {
            return redirect("/login")
        }

        const id = params.id
        const place = query.place
        return store.dispatch("photographer/fetchPage", { id, place, filter: defaultFilter() })
            .catch(() => ({ statusCode: 500, message: "internal error" }))
    },

    computed: {
        ...mapGetters("photographer", ["getPageData"]),
        getPlacesParams() {
            return { photographerId: this.getPageData.photographer.ID }
        },

        getPhotographerName() {
            const name = this.getPageData.photographer.Name || {}
            return `${Format.getName(name.First)} ${Format.getName(name.Last)}`
        },

        getPhotosData() {
            const photosStatistic = this.getPageData.statistic.PhotosStatistic || {}

            return [
                { label: this.$t(`rudiments['uploaded']`), value: photosStatistic.uploaded || 0 },
                { label: this.$t(`rudiments.sold`), value: photosStatistic.sales || 0 },
            ]
        },

        getPhotogroupsData() {
            const photogroupsStatistic = this.getPageData.statistic.PhotoGroupsStatistic || {}

            return [
                {
                    label: this.$t(`rudiments['total created']`),
                    chart: false,
                    value: photogroupsStatistic.createdAll || 0,
                },
                {
                    label: this.$t(`rudiments['app created']`),
                    value: photogroupsStatistic.createdManual || 0,
                },
                {
                    label: this.$t(`rudiments['auto created']`),
                    value: photogroupsStatistic.createdAutomatic || 0,
                },
            ]
        },

        getPhotoreportsData() {
            const photoreportsStatistic = this.getPageData.statistic.PhotoReportsStatistic || {}

            return [
                { label: this.$t(`rudiments.created`), value: photoreportsStatistic.created || 0 },
                { label: this.$t(`rudiments.sold`), value: photoreportsStatistic.sales || 0 },
            ]
        },

        getCharts() {
            return [
                { title: this.$t(`headers['photos']`), data: this.getPhotosData },
                { title: this.$t(`headers['photosets']`), data: this.getPhotogroupsData },
                { title: this.$t(`headers['photoreports']`), data: this.getPhotoreportsData },
            ]
        },

        getFilterRange() {
            return Format.dateRange(
                this.submittedFilter.begin,
                this.submittedFilter.end,
            )
        },
    },

    created() {
        this.setPlace()
    },


    methods: {
        ...mapActions("photographer", ["fetchById", "fetchPage"]),
        setData({ data, place }) {
            this.loaded = true
            this.photographer = data.photographer
            this.statistic = data.statistic
            this.filter.places = place
            this.submittedFilter.place = place
        },

        setPlace() {
            this.filter.places = this.getPageData.place
            this.submittedFilter.place = this.getPageData.place
        },

        fetch() {
            this.loading = true
            return this.fetchPage({
                id: this.photographerId,
                place: this.$route.params.place,
                filter: this.submittedFilter,
            }).then(() => {
                this.setPlace()
                this.loading = false
            })
        },

        onSubmitFilter() {
            this.submittedFilter = { ...this.filter, place: this.filter.place, places: null }
            this.fetch()
        },
    },
}
</script>
<style>
    .page-photographer__filter .form-filter__autocomplete {
        display: none;
    }

    .page-photographer__filter .form-filter__date {
        max-width: none;
    }
</style>
