<template>
    <div>
        <v-list>
            <template v-for="(item, index) in value">
                <v-list-item link :key="index">
                    <v-list-item-content>
                        <v-list-item-title>{{item.name}}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                        <b>{{item.count}}</b>
                    </v-list-item-icon>
                </v-list-item>
                <v-divider :key="`divider-${index}`" v-if="index !== value.length - 1"/>
            </template>
        </v-list>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        }
    }
};
</script>