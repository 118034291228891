<template>
    <div :id="rootId"></div>
</template>

<script>
import Highcharts from "highcharts"
import BaseChart from "./BaseChart"
import BindPieChartModule from "highcharts/modules/variable-pie"

BindPieChartModule(Highcharts)

const getPieOptions = () => ({
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
    },
    title: {
        text: "",
    },
    tooltip: {
        pointFormat: "<b>{point.y:.0f} - ({point.percentage:.1f}%)</b>",
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.y:.0f} ({point.percentage:.1f}%)",
                connectorColor: "silver",
            },
        },
    },
    series: [],
})

export default {
    mixins: [BaseChart],
    methods: {
        init() {
            return Highcharts.chart(this.rootId, getPieOptions())
        },

        onUpdateValue() {
            this.updateSeries([{
                name: this.title,
                data: this.value.map(p => ([p.name, p.count])),
            }])
        },
    },
}
</script>
