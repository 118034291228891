<template>
    <div :id="rootId"></div>
</template>

<script>
import Highcharts from "highcharts"
import BaseChart from "./BaseChart"
import BindFunnelChartModule from "highcharts/modules/funnel"

BindFunnelChartModule(Highcharts)

const getFunnelOptions = () => ({
    chart: {
        type: "funnel",
    },
    title: {
        text: "",
    },
    tooltip: {
        pointFormat: "<b>{point.y:.0f} - ({point.percentage:.1f}%)</b>",
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b> ({point.y:,.0f}) - ({point.percentage:.1f}%)",
                softConnector: true,
            },
            center: ["40%", "50%"],
            neckWidth: "30%",
            neckHeight: "70",
            width: "80%",
        },
    },
    legend: {
        enabled: false,
    },
    series: [],
})

export default {
    mixins: [BaseChart],
    methods: {
        init() {
            return Highcharts.chart(this.rootId, getFunnelOptions())
        },
        onUpdateValue() {
            this.updateSeries([{
                name: this.title,
                data: this.value.map(p => ([p.name, p.count])),
            }])
        },
    },
}
</script>
